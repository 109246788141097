import Vue from 'vue';
import Vuetify from 'vuetify';

import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function createQuoteApp (element) {
    const form = element.querySelector('form');
    if (!form) return;

    Vue.use(Vuetify);

    new Vue({
        el: element,
        vuetify: new Vuetify(),
        data: {
            valid: false,
            fields: {
                accountType: {
                    value: '',
                    items: [ 'Personal Account', 'Business Account' ]
                },
                companyName: { value: '' },
                title: {
                    value: '',
                    items: [ 'Mr', 'Ms', 'Mrs', 'Miss', 'Dr', 'Other' ]
                },
                fullName: { value: '' },
                occupation: { value: '' },
                phoneNumber: {
                    value: '+44',
                    rules: []
                },
                email: {
                    value: '',
                    rules: [ v => EMAIL_PATTERN.test(v) || 'Invalid email address' ]
                },
                fullAddress: { value: '' },
                payment: { value: 'DIRECT DEBIT*' },
                terms: { value: false }
            },
            rules: {
                required: [ v => !!v || 'This field is required' ]
            },
            success: '',
            error: ''
        },
        mounted () {
            this.setPhoneRules();
        },
        methods: {
            setPhoneRules () {
                this.fields.phoneNumber.rules.push(() => {
                    if (validatePhoneNumberLength(this.fields.phoneNumber.value) === undefined) {
                        if (isPossiblePhoneNumber(this.fields.phoneNumber.value) && isValidPhoneNumber(this.fields.phoneNumber.value)) return true;

                        return 'The phone number is not valid';
                    }

                    if (validatePhoneNumberLength(this.fields.phoneNumber.value) === 'TOO_SHORT') return 'The phone number is too short';
                    if (validatePhoneNumberLength(this.fields.phoneNumber.value) === 'TOO_LONG') return 'The phone number is too long';
                    if (validatePhoneNumberLength(this.fields.phoneNumber.value) === 'INVALID_COUNTRY') return 'Please specify the country code';

                    return 'The phone number is not valid';
                });
            },
            async validate () {
                const action = form.getAttribute('action');
                if (!action) return;

                const captcha = document.querySelector('input[name="_wpcf7_recaptcha_response"]');
                if (!captcha) return;

                if (this.valid) {
                    const formData = new FormData(form);

                    formData.append('_wpcf7_recaptcha_response', captcha.value);

                    formData.append('account-type', this.fields.accountType.value);

                    if (this.fields.accountType.value === 'Business Account') {
                        formData.append('company-name', this.fields.companyName.value);
                    }

                    formData.append('title', this.fields.title.value);
                    formData.append('full-name', this.fields.fullName.value);
                    formData.append('occupation', this.fields.occupation.value);
                    formData.append('phone-number', this.fields.phoneNumber.value);
                    formData.append('email', this.fields.email.value);
                    formData.append('full-address', this.fields.fullAddress.value);
                    formData.append('payment', this.fields.payment.value);

                    try {
                        await fetch(action, {
                            method: 'POST',
                            body: formData
                        });

                        this.error = '';
                        this.success = 'Thank you for your message. It has been sent.';
                    } catch (error) {
                        this.success = '';
                        this.error = 'There was an error trying to send your message. Please try again later.';

                        console.error(error);
                    }
                }
            }
        }
    });
}
