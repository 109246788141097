import throwError from '../utils/error';

import createAccount from './create-account';
import getStripeAccount from './get-account';
import updateStripeAccount from './update-account';
import createPayment from './create-payment';
import saveBookingData from './save-data';

export async function finishBooking (fields) {
    try {
        let accountID = null;

        if (fields.payment.value !== 'cash') {
            accountID = await getStripeAccount('Stripe Web');
        } else {
            accountID = await getStripeAccount(fields.name.value);
        }

        if (fields.payment.value === 'cash' || !accountID) {
            if (fields.payment.value !== 'cash') {
                await createAccount('Stripe Web', 'stripe');
            } else {
                await createAccount(fields.name.value, 'cash');
            }
        }

        if (fields.payment.value !== 'cash') {
            accountID = await getStripeAccount('Stripe Web');
        } else {
            accountID = await getStripeAccount(fields.name.value);
        }

        if (fields.payment.value !== 'cash' && !accountID) {
            throwError('Something went wrong, please try again');
            
            return false;
        }

        const vias = [];

        if (fields.via.length) {
            fields.via.forEach(item => {
                vias.push({
                    'lat': item.value.lat,
                    'lng': item.value.lng,
                    'formatted': item.search
                });
            });
        }

        await updateStripeAccount(accountID, 'set', `${fields.notes.value} / passengers: ${fields.passengers.value} / bags: ${fields.bags.value} / hand luggage: ${fields.luggage.value}`);

        const responseBody = {
            'date': `${fields.date.value}T${fields.hours.value}:${fields.minutes.value}Z`,
            'name': fields.name.value,
            'email': fields.email.value,
            'phone': fields.phone.formatted,
            'address': {
                'lat': fields.address.value.lat,
                'lng': fields.address.value.lng,
                'formatted': fields.address.search
            },
            'destination': {
                'lat': fields.destination.value.lat,
                'lng': fields.destination.value.lng,
                'formatted': fields.destination.search
            },
            'vias': vias || [],
            'return_date': fields.return.value ? `${fields.return_date.value}T${fields.return_hours.value}:${fields.return_minutes.value}Z` : '',
            'vehicle_type': fields.vehicletype.value,
            'instructions': `${fields.notes.value} / passengers: ${fields.passengers.value} / bags: ${fields.bags.value} / hand luggage: ${fields.luggage.value}`,
            'account_id': accountID
        };

        const response = await fetch('/wp-json/icabbi/v1/create_booking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(responseBody)
        });

        if (response.ok) {
            const data = await response.json();

            if (data) return data;

            throwError('Something went wrong, please try again');

            return false;
        }

        return false;
    } catch (error) {
        throwError('Something went wrong, please try again');

        return false;
    }
}

export async function addICabbiPayment (intentID, fields, bookingID) {
    try {
        const STRIPE_CHARGE = 0.05;

        const response = await fetch('/wp-json/icabbi/v1/add_payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'amount': fields.price.value.price + Math.round((fields.price.value.price * STRIPE_CHARGE) * 100) / 100,
                'trip_id': bookingID,
                'receipt_id': intentID
            })
        });

        if (response.ok) {
            const data = await response.json();

            if (data) return data;

            throwError('Something went wrong, please try again');

            return false;
        }

        return false;
    } catch (error) {
        throwError('Something went wrong, please try again');

        return false;
    }
}

export async function createBooking (fields, total = null) {
    if (fields.payment.value !== 'cash') {
        const payment = await createPayment(fields, total);

        const savedata = await saveBookingData(fields, payment.productID, payment.priceID, payment.checkout.id);

        if (savedata) return { url: payment.checkout.url };
    } else {
        const booking = await finishBooking(fields);

        return booking.id;
    }

    return false;
}
