export default function initFooter() {
  const footerItems = document.querySelectorAll('.footer__menu > .menu-item');
  if (!footerItems) return;

  footerItems.forEach((el) => {
    const trigger = el.firstChild;
    const panel = el.querySelector('.sub-menu');
    if (trigger && panel) {
      trigger.addEventListener('click', () => {
        el.classList.toggle('active');
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    }
  });
}
