import throwError from '../utils/error';

export default async function getStripeAccount (name) {
    try {
        const response = await fetch(`/wp-json/icabbi/v1/get_account/?name=${name}`);

        if (response.ok) {
            const data = await response.json();

            if (data && data.length) return data[0].id;

            return false;
        }

        return false;
    } catch (error) {
        console.error('Error while retrieving an account:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
