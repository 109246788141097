export default function throwError (status, text = '') {
    const error = document.querySelector('.quote-application--error');
    if (!error) return;

    const message = document.querySelector('.quote-application--error-message');
    if (!message) return;

    if (status === 'close') {
        error.style.display = 'none';
        error.classList.remove('visible');
    }

    if (status === 'show') {
        message.innerHTML = text;

        error.style.display = 'block';

        setTimeout(() => {
            error.classList.add('visible');
        }, 50);
    }
}
