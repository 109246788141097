import throwError from '../utils/error';

export default async function createAccount (name, type) {
    try {
        let requestData = {};

        if (type === 'stripe') {
            requestData = {
                name,
                'type': 'CARD',
                'card_type': 'stripe',
                'profile': 'WEB'
            }
        } else {
            requestData = {
                name,
                'type': 'CASH',
                'profile': 'WEB'
            }
        }

        const response = await fetch('/wp-json/icabbi/v1/create_account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        if (response.ok) {
            const data = await response.json();

            if (data) return data;

            console.error('Failed to create account');
            throwError('show', 'Something went wrong, please try again');

            return false;
        }

        return false;
    } catch (error) {
        console.error('Error while account creation:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
