import { finishBooking, addICabbiPayment } from './create-booking';

import throwError from '../utils/error';

async function addBookingId (product, price, bookingID) {
    try {
        const response = await fetch('/wp-json/stripe/v1/add_booking_id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'product_id': product,
                'price_id': price,
                'booking_id': bookingID
            })
        });

        if (response.ok) {
            const data = await response.json();

            return data;
        }

        return false;
    } catch (error) {
        console.error('Failed to add booking ID:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}

async function checkPaymentStatus (paymentID) {
    try {
        const response = await fetch(`/wp-json/stripe/v1/retrieve_payment?id=${paymentID}`);

        if (response.ok) {
            const data = await response.json();

            return {
                status: data.payment_status,
                intent: data.payment_intent ? data.payment_intent : null
            }
        }

        return false;
    } catch (error) {
        console.error('Failed to fetch payment:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}

export default async function retrieveBookingData (product, price) {
    try {
        const response = await fetch('/wp-json/stripe/v1/retrieve_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'product_id': product,
                'price_id': price
            })
        })

        if (response.ok) {
            const data = await response.json();

            if (data[0].booking_id.length > 2) return { status: 'booked' }

            const paymentStatus = await checkPaymentStatus(data[0].payment_id);

            if (paymentStatus.status === 'unpaid') {
                return { status: 'unpaid' }
            }

            if (paymentStatus.status === 'paid') {
                const fields = JSON.parse(data[0].fields);

                const booking = await finishBooking(fields);

                await addBookingId(product, price, booking.id);

                if (paymentStatus.intent) {
                    await addICabbiPayment(paymentStatus.intent, fields, booking.id);

                    if (booking.return_id) await addICabbiPayment(paymentStatus.intent, fields, booking.return_id)
                }

                return { status: 'paid', booking: booking.id };
            }

            return paymentStatus.status;
        }

        return false;
    } catch (error) {
        console.error('Failed to fetch booking data:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
