import throwError from '../utils/error';

export default async function saveBookingData (fields, product, price, payment) {
    try {
        const response = await fetch('/wp-json/stripe/v1/save_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields,
                'payment_id': payment,
                'product_id': product,
                'price_id': price
            })
        })

        if (response.ok) {
            const data = await response.json();

            if (data) return data;

            console.error('Failed to save data');
            throwError('show', 'Something went wrong, please try again');

            await new Promise(resolve => setTimeout(() => resolve(), 2000));

            return false;
        }

        return false;
    } catch (error) {
        console.error('Failed to save booking data:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
