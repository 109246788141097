export default async function getAddressList (value) {
    try {
        const response = await fetch(`/wp-json/icabbi/v1/get_addresses?value=${value}`);

        const addresses = [];

        if (response.ok) {
            const data = await response.json();

            if (data.length) {
                data.forEach(address => {
                    let fullAddress = '';
                    if (address.organisation) fullAddress += `${address.organisation}, `;
                    if (address.building_number && address.building_number !== '0') fullAddress += `${address.building_number} `;
                    if (address.building) fullAddress += `${address.building} `;
                    if (address.street) fullAddress += `${address.street}`;
                    if (address.area) fullAddress += `, ${address.area},`;
                    if (address.postcode) fullAddress += ` ${address.postcode}`;

                    if (
                        fullAddress &&
                        address.lat &&
                        address.lng
                    )
                    addresses.push({
                        id: address.id || null,
                        text: fullAddress,
                        lat: address.lat,
                        lng: address.lng
                    });
                });
            }
        }

        return addresses;
    } catch (error) {
        console.error('Failed to get address list:', error);

        return [];
    }
}
