import createCorporateApp from './create-app';

export default function initCorporateApp () {
    const section = document.querySelector('.corporate-account');
    if (!section) return;

    const app = section.querySelector('#corporate-account--form');
    if (!app) return;

    createCorporateApp(app);
}
