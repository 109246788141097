import throwError from '../utils/error';

export default async function createPaymentLink (priceID, productID, email) {
    try {
        const response = await fetch('/wp-json/stripe/v1/create_payment_link', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'price': priceID, 'product': productID, email })
        })

        if (response.ok) {
            const data = await response.json();

            return { id: data.id, url: data.url };
        }

        return false;
    } catch (error) {
        console.error('Error while price creation:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
