export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        const context = this;

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
}

export const DEBOUNCE_DELAY = 500;
