import Swiper, { Navigation, Scrollbar } from 'swiper';

export default function initCards() {
  const cards = document.querySelectorAll('.cards');
  if (!cards) return;

  cards.forEach((el) => {
    const slider = el.querySelector('.swiper');
    const arrowNext = el.querySelector('.swiper-button-next');
    const arrowPrev = el.querySelector('.swiper-button-prev');

    const swiper = new Swiper(slider, {
      modules: [Navigation, Scrollbar],
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 1.5
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      },
      navigation: {
        nextEl: arrowNext,
        prevEl: arrowPrev
      }
    });
    swiper.init();
  });
}
