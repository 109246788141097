import createProduct from './create-product';
import createPrice from './create-price';
import createPaymentLink from './create-payment-link';

import throwError from '../utils/error';

export default async function createPayment (fields, total) {
    try {
        const productID = await createProduct(fields.name.value, `Booking by ${fields.name.value}`);

        if (!productID) {
            console.error('Imposible to create the product');
            throwError('show', 'Something went wrong, please try again');
    
            return false;
        }
    
        const priceID = await createPrice(total, productID);
    
        if (!priceID) {
            console.error('Imposible to create the price');
            throwError('show', 'Something went wrong, please try again');
    
            return false;
        }
    
        const checkout = await createPaymentLink(priceID, productID, fields.email.value);
    
        const checkoutID = checkout.id;
        const checkoutURL = checkout.url;
    
        if (!checkoutID || !checkoutURL) {
            console.error('Imposible to create checkout');
            throwError('show', 'Something went wrong, please try again');
    
            return false;
        }

        return { productID, priceID, checkout };
    } catch (error) {
        console.error('Error while payment creation:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
