import throwError from '../utils/error';

export default async function createPrice (amount, productID) {
    try {
        const response = await fetch('/wp-json/stripe/v1/create_price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'unit_amount': amount.toFixed(2).replace('.', ''),
                'product': productID
            })
        })

        if (response.ok) {
            const data = await response.json();

            return data.id;
        }

        return false;
    } catch (error) {
        console.error('Error while price creation:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
