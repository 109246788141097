export default function initPreloader() {
  const preloader = document.querySelector('.preloader');
  const body = document.body;

  if (!preloader) return;

  window.onload = () => {
    setTimeout(() => {
      preloader.style.display = 'none';
      body.classList.remove('preloader-active');
    }, 1000);
  };
}
