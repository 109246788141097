import initPreloader from './modules/preolader';
import initNavigation from './modules/navigation';
import initSwiperCars from './modules/swiper-cars';
import initSwiperTestimonials from './modules/swiper-testimonials';
import initSwiperInfo from './modules/swiper-info';
import initCards from './modules/cards';
import initFaq from './modules/faq';
import initFooter from './modules/footer';

import initQuoteApp from './modules/application';
import initCorporateApp from './modules/corporate-account';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  initPreloader();
  initNavigation();
  initSwiperCars();
  initSwiperTestimonials();
  initSwiperInfo();
  initCards();
  initFaq();
  initFooter();

  initQuoteApp();
  initCorporateApp();
});
