import Vue from 'vue';
import Vuetify from 'vuetify';
import { parseISO, format } from 'date-fns';

import { DEBOUNCE_DELAY, debounce } from '../utils/debounce';
import metersToMiles from '../utils/distance';
import timeConverter from '../utils/time';
import checkDate from '../utils/date';
import checkReturnDate from '../utils/return-date';
import VEHICLE_CONFIG from '../utils/vehicles';
import generateUniqueKey from '../utils/key';
import throwError from '../utils/error';

import getVehicleTypes from './get-vehicle-types';
import getAddressList from './get-address-list';
import getTheQuote from './get-the-quote';

const preloader = document.querySelector('.preloader');

export default function createHeroApp (element) {
    Vue.use(Vuetify);

    new Vue({
        el: element,
        vuetify: new Vuetify(),
        data: {
            step: 1,
            forms: [ { valid: false } ],
            fields: {
                address: {
                    search: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the address' ],
                },
                destination: {
                    search: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the destination' ]
                },
                via: [],
                date: {
                    menu: false,
                    value: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                },
                hours: { value: '00', items: [] },
                minutes: { value: '00', items: [] },
                return: { value: false },
                return_date: {
                    menu: false,
                    value: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                },
                return_hours: { value: '00', items: [] },
                return_minutes: { value: '00', items: [] }
            },
            content: {},
            quotes: [],
            vehicletypes: null
        },
        computed: {
            computedDateFormatted () {
                return this.fields.date.value ? format(parseISO(this.fields.date.value), 'EEEE d MMMM yyyy') : ''
            },
            computedReturnDateFormatted () {
                return this.fields.return_date.value ? format(parseISO(this.fields.return_date.value), 'EEEE d MMMM yyyy') : ''
            }
        },
        mounted () {
            this.fillHours();
            this.fillMinutes();
            this.setAddressRules();
        },
        watch: {
            'fields.address.search': {
                handler: debounce(function (value, prev) {
                    if (!this.fields.address.ready) {
                        this.fields.address.items = [];
                        this.fields.address.ready = false;
                        this.fields.address.value = null;

                        if (value.length >= 3) this.setAddressList(this.fields.address, value);
                    } else {
                        this.fields.address.items = [];

                        if (value !== prev) this.fields.address.ready = false;
                    }
                }, DEBOUNCE_DELAY),
                deep: true
            },
            'fields.destination.search': {
                handler: debounce(function (value, prev) {
                    if (!this.fields.destination.ready) {
                        this.fields.destination.items = [];
                        this.fields.destination.ready = false;
                        this.fields.destination.value = null;

                        if (value.length >= 3) this.setAddressList(this.fields.destination, value);
                    } else {
                        this.fields.destination.items = [];

                        if (value !== prev) this.fields.destination.ready = false;
                    }
                }, DEBOUNCE_DELAY),
                deep: true
            },
            'fields': {
                handler: function () {
                  this.handleError('close');
                },
              deep: true
            },
            step () {
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
        },
        methods: {
            async setAddressList (field, value) {
                const result = await getAddressList(value);

                if (result.length) {
                    result.forEach(address => {
                        field.items.push({
                            id: address.id,
                            text: address.text,
                            lat: address.lat,
                            lng: address.lng
                        });
                    });
                }
            },
            async setVehicleTypes () {
                if (!this.vehicletypes) {
                    const result = await getVehicleTypes();

                    if (result.length) {
                        this.vehicletypes = result;

                        result.forEach((type, index) => {
                            this.vehicletypes[index].bags = VEHICLE_CONFIG[type.key].bags || 2;
                            this.vehicletypes[index].luggage = VEHICLE_CONFIG[type.key].luggage || 2;
                            this.vehicletypes[index].title = VEHICLE_CONFIG[type.key].title || this.vehicletypes[index].title;
                            this.vehicletypes[index].title = VEHICLE_CONFIG[type.key].title || this.vehicletypes[index].title;

                            if (VEHICLE_CONFIG[type.key].seats) {
                                this.vehicletypes[index].seats = VEHICLE_CONFIG[type.key].seats || 4;
                            }
                        });

                        return true;
                    }

                    return false;
                }

                return true;
            },
            setAddress (field, data) {
                this.fields[field].value = {
                    lat: data.lat,
                    lng: data.lng
                };

                this.fields[field].ready = true;

                this.fields[field].search = data.text;
            },
            setViaAddress (key, data) {
                const field = this.fields.via.filter(via => via.key === key)[0];

                if (field) {
                    field.value = {
                        id: data.id,
                        lat: data.lat,
                        lng: data.lng
                    }
    
                    field.ready = true;
                    field.search = data.text;
                }
            },
            fillHours () {
                for (let i = 0; i < 24; i += 1) {
                    if (i < 10) { 
                        this.fields.hours.items.push(`0${i}`);
                        this.fields.return_hours.items.push(`0${i}`);
                    } else {
                        this.fields.hours.items.push(`${i}`);
                        this.fields.return_hours.items.push(`${i}`);
                    }
                }
            },
            fillMinutes () {
                let i = 0;

                while (i < 60) {
                    if (i < 10) { 
                        this.fields.minutes.items.push(`0${i}`);
                        this.fields.return_minutes.items.push(`0${i}`);
                    } else {
                        this.fields.minutes.items.push(`${i}`);
                        this.fields.return_minutes.items.push(`${i}`);
                    }

                    i += 5;
                }
            },
            setAddressRules () {
                this.fields.address.rules.push(() => !!this.fields.address.value || 'Please select address from the list');
                this.fields.destination.rules.push(() => !!this.fields.destination.value || 'Please select address from the list');
            },
            setViaRules (key) {
                const field = this.fields.via.filter(via => via.key === key)[0];
                if (field) field.rules.push(() => !!field.value || 'Please select address from the list');
            },
            setViaWatcher (key) {
                const field = this.fields.via.filter(via => via.key === key)[0];

                if (field) {
                    const vm = this;

                    const debouncedFunction = debounce(function (value, prev) {
                        if (!field.ready) {
                            field.items = [];
                            field.ready = false;
                            field.value = null;
                    
                            if (value.length >= 3) vm.setAddressList(field, value);
                        } else {
                            field.items = [];
                    
                            if (value !== prev) field.ready = false;
                        }
                    }, DEBOUNCE_DELAY);

                    this.$watch(() => field.search, (value, prev) => {
                        debouncedFunction(value, prev);
                    });
                }
            },
            addViaPoint () {
                const key = generateUniqueKey();

                this.fields.via.push({
                    key: key,
                    search: '',
                    prevSearch: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the address' ],
                });

                this.setViaRules(key);
                this.setViaWatcher(key);
            },
            removeViaPoint (key) {
                this.fields.via = this.fields.via.filter(via => via.key !== key);
            },
            handleError (status, text = '') {
                throwError(status, text);
            },
            async validateStep (step = 1) {
                if (step === 1 && this.forms[0].valid) {
                    const isvehicletypes = await this.setVehicleTypes();
                    const isdatecorrect = checkDate(
                        this.fields.date.value,
                        this.fields.hours.value,
                        this.fields.minutes.value,
                    );

                    let isreturndatecorrect = true;

                    if (this.fields.return.value) {
                        isreturndatecorrect = checkReturnDate(
                            {
                                d: this.fields.date.value,
                                h: this.fields.hours.value,
                                m: this.fields.minutes.value
                            },
                            {
                                d: this.fields.return_date.value,
                                h: this.fields.return_hours.value,
                                m: this.fields.return_minutes.value
                            }
                        );
                    }

                    if (!isdatecorrect) {
                      this.handleError('show', 'Pick-up time can\'t be erlier than current time');

                      return;
                    }
                    
                    if (!isreturndatecorrect) {
                        this.handleError('show', 'Return date can\'t be erlier than pick-up time');
  
                        return;
                    }

                    if (!isvehicletypes) {
                      this.handleError('show', 'No vehicles available, please try again');

                      return;
                    }

                    this.handlePreloader(true);

                    let locations = [
                        `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                        `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`
                    ];

                    let returnLocations = [
                        `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`,
                        `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                    ];

                    if (this.fields.via.length) {
                        const vias = [];

                        this.fields.via.forEach(via => {
                            vias.push(`${via.value.lat},${via.value.lng}`);
                        });

                        locations = [
                            `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                            ...vias,
                            `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`
                        ];

                        returnLocations = [
                            `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`,
                            ...vias.slice().reverse(),
                            `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                        ];
                    }

                    const result = await getTheQuote(
                        {
                            date: `${this.fields.date.value}T${this.fields.hours.value}:${this.fields.minutes.value}Z`,
                            locations,
                            via: this.fields.via,
                            vehicletypes: this.vehicletypes
                        },
                        {
                            isReturn: this.fields.return.value,
                            locations: returnLocations,
                            date: `${this.fields.return_date.value || ''}T${this.fields.return_hours.value || ''}:${this.fields.return_minutes.value || ''}Z`,
                        }
                    );

                    if (result && result.content && result.quotes) {
                        this.content.distance = metersToMiles(result.content.distance);
                        this.content.time = timeConverter(result.content.time);
                        this.quotes = result.quotes;

                        const lsFields = JSON.stringify(this.fields);
                        const lsVehicles = JSON.stringify(this.vehicletypes);
                        const lsContent = JSON.stringify(this.content);
                        const lsQuotes = JSON.stringify(this.quotes);

                        if (localStorage) {
                            localStorage.setItem('fields', lsFields);
                            localStorage.setItem('vehicletypes', lsVehicles);
                            localStorage.setItem('content', lsContent);
                            localStorage.setItem('quotes', lsQuotes);
                        }

                        window.location.replace(`${window.location.origin}/get-a-quote?proceed_step=2`);
                    } else {
                        this.handlePreloader(false);
                        this.handleError('show', 'Something went wrong, please try again');
                    }
                }
            },
            handlePreloader (state) {
                if (!preloader) return;

                if (state) preloader.style.display = 'block';
                if (!state) preloader.style.display = 'none';
            }
        }
    });
}
