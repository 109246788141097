export default function timeConverter (minutes) {
    if (minutes < 60) {
        return { hours: null, minutes: minutes };
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return { hours: hours, minutes: remainingMinutes };
}
