import { DEBOUNCE_DELAY } from '../utils/debounce';

import throwError from '../utils/error';

export default async function getTheQuote (values, returnTrip) {
    const content = {
        distance: null,
        time: null
    };
    const quotes = [];

    const getSingleQuote = async (type, date, locations) => {
        try {
            const response = await fetch('/wp-json/icabbi/v1/get_quote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'date': date,
                    'locations': locations,
                    'vehicle_type': type
                })
            });

            if (response.ok) {
                const data = await response.json();

                if (data) return data;

                console.error('Failed to fetch quote');
                throwError('show', 'Something went wrong, please try again');

                await new Promise(resolve => setTimeout(() => resolve(), 2000));

                return false;
            }

            console.error('Failed to fetch quote');
            throwError('show', 'Something went wrong, please try again');

            await new Promise(resolve => setTimeout(() => resolve(), 2000));

            return false;
        } catch (error) {
            console.error('Failed to fetch quote:', error);
            throwError('show', 'Something went wrong, please try again');

            return false;
        }
    };

    try {
        const promises = [];

        for (let i = 0; i < values.vehicletypes.length; i += 1) {
            const promise = new Promise(async resolve => {
                await new Promise(delayResolve => setTimeout(delayResolve, DEBOUNCE_DELAY * i));

                const data = await getSingleQuote(values.vehicletypes[i].key, values.date, values.locations);

                const iscontent = content.distance && !content.duration_minutes;
        
                if (data) {
                    const isdatacontent = data.body.distance && data.body.duration_minutes;
        
                    if (!iscontent && isdatacontent) {
                        content.distance = data.body.distance;
                        content.time = data.body.duration_minutes;
                    }
        
                    if (data.body.quotes.length) {
                        if (returnTrip.isReturn) {
                            await new Promise(delayResolve => setTimeout(delayResolve, DEBOUNCE_DELAY));

                            const returnData = await getSingleQuote(values.vehicletypes[i].key, returnTrip.date, returnTrip.locations);

                            if (returnData) {
                                data.body.quotes[0].price.price += returnData.body.quotes[0].price.price;
                                data.body.quotes[0].price.cost += returnData.body.quotes[0].price.cost;
                            }
                        }

                        quotes.push({ price: data.body.quotes[0].price, vehicle: data.body.quotes[0].vehicle });
                    }
                }

                return resolve();
            });

            promises.push(promise);
        }

        await Promise.all(promises);
    } catch (error) {
        console.error('Error while fetching quotes:', error);
        throwError('show', 'Something went wrong, please try again');
    }

    return { content, quotes };
}
