import Vue from 'vue';
import Vuetify from 'vuetify';
import { parseISO, format } from 'date-fns';
import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { DEBOUNCE_DELAY, debounce } from '../utils/debounce';
import metersToMiles from '../utils/distance';
import timeConverter from '../utils/time';
import checkDate from '../utils/date';
import checkReturnDate from '../utils/return-date';
import VEHICLE_CONFIG from '../utils/vehicles';
import generateUniqueKey from '../utils/key';
import throwError from '../utils/error';

import getVehicleTypes from './get-vehicle-types';
import getAddressList from './get-address-list';
import getTheQuote from './get-the-quote';
import { createBooking } from './create-booking';
import retrieveBookingData from './retrieve-data';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const STRIPE_CHARGE = 0.05;

const preloader = document.querySelector('.preloader');

export default function createQuoteApp (element) {
    Vue.use(Vuetify);

    new Vue({
        el: element,
        vuetify: new Vuetify(),
        data: {
            step: 1,
            forms: [ { valid: false }, { valid: false } ],
            fields: {
                address: {
                    search: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the address' ],
                },
                destination: {
                    search: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the destination' ]
                },
                via: [],
                date: {
                    menu: false,
                    value: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                },
                hours: { value: '00', items: [] },
                minutes: { value: '00', items: [] },
                return: { value: false },
                return_date: {
                    menu: false,
                    value: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
                },
                return_hours: { value: '00', items: [] },
                return_minutes: { value: '00', items: [] },
                vehicletype: { value: null },
                price: { value: null },
                name: { value: '', rules: [ v => !!v || 'Please enter passenger\'s name' ] },
                email: {
                    value: '',
                    rules: [
                        v => !!v || 'Please enter email address',
                        v => EMAIL_PATTERN.test(v) || 'Invalid email address'
                    ]
                },
                confemail: {
                    value: '',
                    rules: [
                        v => !!v || 'Please re-confirm email address',
                        v => EMAIL_PATTERN.test(v) || 'Invalid email address'
                    ]
                },
                phone: { value: '+44', formatted: '+44', rules: [ v => !!v || 'Please enter contact phone number' ] },
                passengers: { value: 1, items: [ 1 ] },
                bags: { value: 0, items: [ 0 ] },
                luggage: { value: 0, items: [ 0 ] },
                notes: { value: '', rules: [ v => v.length <= 60 || 'Max 60 characters only' ] },
                payment: { value: 'cash' },
                privacy: { value: false, rules: [ v => !!v || 'Terms and Condition Required' ] }
            },
            vehicletypes: null,
            content: {},
            quotes: [],
            bookingid: null,
            proceedstep: false,
            proceedstepset: true
        },
        computed: {
            computedDateFormatted () {
                return this.fields.date.value ? format(parseISO(this.fields.date.value), 'EEEE d MMMM yyyy') : ''
            },
            computedReturnDateFormatted () {
                return this.fields.return_date.value ? format(parseISO(this.fields.return_date.value), 'EEEE d MMMM yyyy') : ''
            },
            computedTotal () {
                if (this.fields.payment.value === 'cash') return this.fields.price.value.price;

                return this.fields.price.value.price + Math.round((this.fields.price.value.price * STRIPE_CHARGE) * 100) / 100;
            },
            computedCharge () {
                if (this.fields.payment.value === 'cash') return Number(0);

                return STRIPE_CHARGE * 100;
            },
            computedChargeAmount () {
                if (this.fields.payment.value === 'cash') return Number(0);

                return this.fields.price.value.price * STRIPE_CHARGE;
            }
        },
        created () {
            if (window.location.search) {
                this.handlePreloader(true);

                const queryParams = window.location.search.slice(1).split('&');

                const paramsObject = { proceed_step: null, checkout: '', product: '', price: '' };

                queryParams.forEach(param => {
                    const [key, value] = param.split('=');
                    paramsObject[key] = value;
                });

                if (paramsObject.checkout && paramsObject.checkout === 'cancel') {
                    this.step = 6;

                    this.handlePreloader(false);
                }

                if (paramsObject.checkout && paramsObject.checkout === 'success') {
                    this.confirmPayment(paramsObject.product, paramsObject.price);
                }

                if (paramsObject.proceed_step && paramsObject.proceed_step === '2') {
                    if (localStorage) {
                        this.proceedstepset = false;
                        this.setStepTwoValues();

                        this.step = 2;
                    }

                    this.handlePreloader(false);
                }
            }
        },
        mounted () {
            this.fillHours();
            this.fillMinutes();

            this.setAddressRules();
            this.setConfemailRules();
            this.setPhoneRules();
        },
        watch: {
            'fields.address.search': {
                handler: debounce(function (value, prev) {
                    if (this.proceedstepset) {
                        if (!this.fields.address.ready) {
                            this.fields.address.items = [];
                            this.fields.address.ready = false;
                            this.fields.address.value = null;
    
                            if (value.length >= 3) this.setAddressList(this.fields.address, value);
                        } else {
                            this.fields.address.items = [];
    
                            if (value !== prev) this.fields.address.ready = false;
                        }
                    }
                }, DEBOUNCE_DELAY),
                deep: true
            },
            'fields.destination.search': {
                handler: debounce(function (value, prev) {
                    if (this.proceedstepset) {
                        if (!this.fields.destination.ready) {
                            this.fields.destination.items = [];
                            this.fields.destination.ready = false;
                            this.fields.destination.value = null;

                            if (value.length >= 3) this.setAddressList(this.fields.destination, value);
                        } else {
                            this.fields.destination.items = [];

                            if (value !== prev) this.fields.destination.ready = false;
                        }
                    } else {
                        this.proceedstepset = true;
                    }
                }, DEBOUNCE_DELAY),
                deep: true
            },
            'fields.phone.value': {
                handler: function (value) {
                    this.fields.phone.formatted = value.replaceAll(' ', '');
                },
                deep: true
            },
            'fields': {
                handler: function () {
                  this.handleError('close');
                },
              deep: true
            },
            step () {
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
        },
        methods: {
            async setAddressList (field, value) {
                const result = await getAddressList(value);

                if (result.length) {
                    result.forEach(address => {
                        field.items.push({
                            id: address.id,
                            text: address.text,
                            lat: address.lat,
                            lng: address.lng
                        });
                    });
                }
            },
            async setVehicleTypes () {
                if (!this.vehicletypes) {
                    const result = await getVehicleTypes();

                    if (result.length) {
                        this.vehicletypes = result;

                        result.forEach((type, index) => {
                            this.vehicletypes[index].bags = VEHICLE_CONFIG[type.key].bags || 2;
                            this.vehicletypes[index].luggage = VEHICLE_CONFIG[type.key].luggage || 2;
                            this.vehicletypes[index].title = VEHICLE_CONFIG[type.key].title || this.vehicletypes[index].title;
                            this.vehicletypes[index].title = VEHICLE_CONFIG[type.key].title || this.vehicletypes[index].title;

                            if (VEHICLE_CONFIG[type.key].seats) {
                                this.vehicletypes[index].seats = VEHICLE_CONFIG[type.key].seats || 4;
                            }
                        });

                        return true;
                    }

                    return false;
                }

                return true;
            },
            setAddress (field, data) {
                this.fields[field].value = {
                    lat: data.lat,
                    lng: data.lng
                };

                this.fields[field].ready = true;

                this.fields[field].search = data.text;
            },
            setViaAddress (key, data) {
                const field = this.fields.via.filter(via => via.key === key)[0];

                if (field) {
                    field.value = {
                        id: data.id,
                        lat: data.lat,
                        lng: data.lng
                    }
    
                    field.ready = true;
                    field.search = data.text;
                }
            },
            fillHours () {
                for (let i = 0; i < 24; i += 1) {
                    if (i < 10) { 
                        this.fields.hours.items.push(`0${i}`);
                        this.fields.return_hours.items.push(`0${i}`);
                    } else {
                        this.fields.hours.items.push(`${i}`);
                        this.fields.return_hours.items.push(`${i}`);
                    }
                }
            },
            fillMinutes () {
                let i = 0;

                while (i < 60) {
                    if (i < 10) { 
                        this.fields.minutes.items.push(`0${i}`);
                        this.fields.return_minutes.items.push(`0${i}`);
                    } else {
                        this.fields.minutes.items.push(`${i}`);
                        this.fields.return_minutes.items.push(`${i}`);
                    }

                    i += 5;
                }
            },
            fillPassengers () {
                const type = this.vehicletypes.filter(vehicle => vehicle.key === this.fields.vehicletype.value)[0];

                for (let i = 1; i < type.seats; i += 1) {
                    this.fields.passengers.items.push(i + 1);
                }

                for (let i = 0; i < type.bags; i += 1) {
                    this.fields.bags.items.push(i + 1);
                }

                for (let i = 0; i < type.luggage; i += 1) {
                    this.fields.luggage.items.push(i + 1);
                }
            },
            setAddressRules () {
                this.fields.address.rules.push(() => !!this.fields.address.value || 'Please select address from the list');
                this.fields.destination.rules.push(() => !!this.fields.destination.value || 'Please select address from the list');
            },
            setViaRules (key) {
                const field = this.fields.via.filter(via => via.key === key)[0];
                if (field) field.rules.push(() => !!field.value || 'Please select address from the list');
            },
            setViaWatcher (key) {
                const field = this.fields.via.filter(via => via.key === key)[0];

                if (field) {
                    const vm = this;

                    const debouncedFunction = debounce(function (value, prev) {
                        if (!field.ready) {
                            field.items = [];
                            field.ready = false;
                            field.value = null;
                    
                            if (value.length >= 3) vm.setAddressList(field, value);
                        } else {
                            field.items = [];
                    
                            if (value !== prev) field.ready = false;
                        }
                    }, DEBOUNCE_DELAY);

                    this.$watch(() => field.search, (value, prev) => {
                        debouncedFunction(value, prev);
                    });
                }
            },
            setConfemailRules () {
                this.fields.confemail.rules.push(() => !!(this.fields.confemail.value === this.fields.email.value) || 'The email address confirmation does not match');
            },
            setPhoneRules () {
                this.fields.phone.rules.push(() => {
                    if (validatePhoneNumberLength(this.fields.phone.value) === undefined) {
                        if (isPossiblePhoneNumber(this.fields.phone.value) && isValidPhoneNumber(this.fields.phone.value)) return true;

                        return 'The phone number is not valid';
                    }

                    if (validatePhoneNumberLength(this.fields.phone.value) === 'TOO_SHORT') return 'The phone number is too short';
                    if (validatePhoneNumberLength(this.fields.phone.value) === 'TOO_LONG') return 'The phone number is too long';
                    if (validatePhoneNumberLength(this.fields.phone.value) === 'INVALID_COUNTRY') return 'Please specify the country code';

                    return 'The phone number is not valid';
                });
            },
            addViaPoint () {
                const key = generateUniqueKey();

                this.fields.via.push({
                    key: key,
                    search: '',
                    prevSearch: '',
                    items: [],
                    value: null,
                    ready: false,
                    rules: [ v => !!v || 'Please specify the address' ],
                });

                this.setViaRules(key);
                this.setViaWatcher(key);
            },
            removeViaPoint (key) {
                this.fields.via = this.fields.via.filter(via => via.key !== key);
            },
            handleError (status, text = '') {
                throwError(status, text);
            },
            async confirmPayment (product, price) {
                this.handlePreloader(true);

                const result = await retrieveBookingData(product, price);

                if (result && result.status && result.status === 'unpaid') {
                    this.step = 6;

                    this.handlePreloader(false);
                }

                if (result && result.status && result.status === 'paid') {
                    this.bookingid = result.booking;
                    this.step = 5;
                    
                    this.handlePreloader(false);
                }

                if (result && result.status && result.status === 'booked') {
                    window.location.replace(window.location.origin);
                }
            },
            async validateStep (step, data = null) {
                if (step === 1 && this.forms[0].valid) {
                    const isvehicletypes = await this.setVehicleTypes();
                    const isdatecorrect = checkDate(
                        this.fields.date.value,
                        this.fields.hours.value,
                        this.fields.minutes.value,
                    );

                    let isreturndatecorrect = true;

                    if (this.fields.return.value) {
                        isreturndatecorrect = checkReturnDate(
                            {
                                d: this.fields.date.value,
                                h: this.fields.hours.value,
                                m: this.fields.minutes.value
                            },
                            {
                                d: this.fields.return_date.value,
                                h: this.fields.return_hours.value,
                                m: this.fields.return_minutes.value
                            }
                        );
                    }

                    if (!isdatecorrect) {
                      this.handleError('show', 'Pick-up time can\'t be erlier than current time');

                      return;
                    }
                    
                    if (!isreturndatecorrect) {
                        this.handleError('show', 'Return date can\'t be erlier than pick-up time');
  
                        return;
                      }

                    if (!isvehicletypes) {
                      this.handleError('show', 'No vehicles available, please try again');

                      return;
                    }

                    this.handlePreloader(true);

                    let locations = [
                        `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                        `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`
                    ];

                    let returnLocations = [
                        `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`,
                        `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                    ];

                    if (this.fields.via.length) {
                        const vias = [];

                        this.fields.via.forEach(via => {
                            vias.push(`${via.value.lat},${via.value.lng}`);
                        });

                        locations = [
                            `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                            ...vias,
                            `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`
                        ];

                        returnLocations = [
                            `${this.fields.destination.value.lat},${this.fields.destination.value.lng}`,
                            ...vias.slice().reverse(),
                            `${this.fields.address.value.lat},${this.fields.address.value.lng}`,
                        ];
                    }

                    const result = await getTheQuote(
                        {
                            date: `${this.fields.date.value}T${this.fields.hours.value}:${this.fields.minutes.value}Z`,
                            locations,
                            via: this.fields.via,
                            vehicletypes: this.vehicletypes
                        },
                        {
                            isReturn: this.fields.return.value,
                            locations: returnLocations,
                            date: `${this.fields.return_date.value || ''}T${this.fields.return_hours.value || ''}:${this.fields.return_minutes.value || ''}Z`,
                        }
                    );

                    if (result && result.content && result.quotes) {
                        this.content.distance = metersToMiles(result.content.distance);
                        this.content.time = timeConverter(result.content.time);
                        this.quotes = result.quotes;

                        this.handlePreloader(false);
                        this.step = 2;
                    }
                }

                if (step === 2) {
                    this.handlePreloader(true);

                    this.fields.vehicletype.value = data.type;
                    this.fields.price.value = data.price;

                    this.fillPassengers();

                    setTimeout(() => {
                        this.handlePreloader(false);
                        this.step = 3;
                    }, 1000);
                }

                if (step === 3) {
                    this.handlePreloader(true);

                    setTimeout(() => {
                        this.handlePreloader(false);
                        this.step = 4;
                    }, 1000);
                }

                if (step === 4) {
                    this.handlePreloader(true);

                    const result = await createBooking(this.fields, this.computedTotal);

                    if (result && typeof result !== 'object') {
                        this.bookingid = result;

                        this.handlePreloader(false);
                        this.step = 5;
                    } else if (result && typeof result === 'object' && result.url) {
                        window.location.replace(result.url);
                    } else {
                        this.handleError('show', 'Something went wrong while booking createment, please try again');
                    }
                }
            },
            handlePreloader (state) {
                if (!preloader) return;

                if (state) preloader.style.display = 'block';
                if (!state) preloader.style.display = 'none';
            },
            setStepTwoValues () {
                const lsFields = localStorage.getItem('fields');
                const lsVehicles = localStorage.getItem('vehicletypes');
                const lsContent = localStorage.getItem('content');
                const lsQuotes = localStorage.getItem('quotes');
                if (!lsFields || !lsVehicles || !lsContent || !lsQuotes) {
                    this.step = 1;

                    return;
                }

                const fields = JSON.parse(lsFields);
                const vehicletypes = JSON.parse(lsVehicles);
                const content = JSON.parse(lsContent);
                const quotes = JSON.parse(lsQuotes);

                if (!fields || !vehicletypes || !content || !quotes) {
                    this.step = 1;

                    return;
                }

                Object.keys(fields).forEach(key => {
                    if (key !== 'hours' && key !== 'minutes' && key !== 'return_hours' && key !== 'return_minutes') {
                        this.fields[key] = null;
                        this.fields[key] = fields[key];
                    }

                    switch (key) {
                        case 'hours':
                            this.fields.hours = fields.hours;
                            break;
                        case 'minutes':
                            this.fields.minutes = fields.minutes;
                            break;
                        case 'return_hours':
                            this.fields.return_hours = fields.return_hours;
                            break;
                        case 'return_minutes':
                            this.fields.return_minutes = fields.return_minutes;
                            break;
                        default:
                            break;
                    }
                });

                this.vehicletypes = vehicletypes;
                this.content = content;
                this.quotes = quotes;
            }
        }
    });
}
