export default function initFaq() {
  const faq = document.querySelectorAll('.faq');
  if (!faq) return;

  faq.forEach((el) => {
    const faqItem = el.querySelectorAll('.faq__item');
    faqItem.forEach((item) => {
      const panel = item.querySelector('.faq__item-text');
      item.addEventListener('click', () => {
        item.classList.toggle('active');
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    });
  });
}
