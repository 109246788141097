import VEHICLE_CONFIG from '../utils/vehicles';

import throwError from '../utils/error';

export default async function getVehicleTypes () {
    try {
        const response = await fetch('/wp-json/icabbi/v1/get_vehicle_types');

        const vehicletypes = [];

        if (response.ok) {
            const data = await response.json();

            if (data.length) {
                data.forEach(type => {
                    if (type.group === 'AVAILABLE') vehicletypes.push(type);
                });
            }
        }

        const vehicleKeys = vehicletypes.map(vehicle => vehicle.key);

        const orderedVehicleTypes = VEHICLE_CONFIG.order.reduce((ordered, type) => {
        const index = vehicleKeys.indexOf(type);
            if (index !== -1) {
                ordered.push(vehicletypes[index]);
            }

            return ordered;
        }, []);

        const typesNotInConfig = vehicletypes.filter(vehicle => !VEHICLE_CONFIG.order.includes(vehicle.key));

        return orderedVehicleTypes.concat(typesNotInConfig);
    } catch (error) {
        console.error('Failed to fetch vehicle types:', error);
        throwError('show', 'Something went wrong, please try again');

        return [];
    }
}
