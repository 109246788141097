import createQuoteApp from './modules/create-app';
import createHeroApp from './modules/create-hero-app';

export default function initQuoteApp () {
    const section = document.querySelector('.quote-application');
    const hero = document.querySelector('.hero1');

    if (!section && !hero) return;

    let app;
    let heroApp;

    if (section) app = section.querySelector('#quote-application-app');
    if (hero) heroApp = hero.querySelector('#hero-application-app');

    if (app) createQuoteApp(app);
    if (heroApp) createHeroApp(heroApp);
}
