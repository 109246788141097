import throwError from '../utils/error';

export default async function createProduct (name, desc) {
    try {
        const response = await fetch('/wp-json/stripe/v1/create_product', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, desc })
        })

        if (response.ok) {
            const data = await response.json();

            return data.id;
        }

        return false;
    } catch (error) {
        console.error('Error while product creation:', error);
        throwError('show', 'Something went wrong, please try again');

        return false;
    }
}
