const VEHICLE_CONFIG = {
    order: [ 'S', 'X1', 'E4', 'M5', 'M6', 'R8' ],

    'S': {
        title: 'Saloon Car',
        bags: 2,
        luggage: 4
    },
    'E4': {
        title: 'Estate-Car',
        bags: 2,
        luggage: 4
    },
    'M5': {
        title: 'MPV-Car',
        seats: 4,
        bags: 4,
        luggage: 4
    },
    'M6': {
        title: 'MPV-Car',
        seats: 6,
        bags: 1,
        luggage: 1
    },
    'X1': {
        title: 'Executive',
        bags: 2,
        luggage: 2
    },
    'R8': {
        title: '8-Seater Bus',
        bags: 8,
        luggage: 8
    }
};

export default VEHICLE_CONFIG;
