export default function checkDate (d, h, m) {
    const date = new Date(`${d}T${h}:${m}:00`);
    const currentDate = new Date();

    if (date.getTime() >= currentDate) {
      return true;
    }

    return false;
}
