import Swiper, { Navigation, Scrollbar } from 'swiper';

export default function initSwiperCars() {
  const swiperCars = document.querySelectorAll('.swiper-cars');
  if (!swiperCars) return;

  swiperCars.forEach((el) => {
    const slider = el.querySelector('.swiper');
    const arrowNext = el.querySelector('.swiper-button-next');
    const arrowPrev = el.querySelector('.swiper-button-prev');
    const scrollbarSwiper = el.querySelector('.swiper-cars__scrollbar');

    const swiper = new Swiper(slider, {
      modules: [Navigation, Scrollbar],
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 1.5
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      },
      scrollbar: {
        el: scrollbarSwiper,
        draggable: true
      },
      navigation: {
        nextEl: arrowNext,
        prevEl: arrowPrev
      }
    });
    swiper.init();
  });
}
