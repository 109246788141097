export default function initNavigation() {
  const navigation = document.querySelector('.header__navigation');
  const navigationBtn = document.querySelector('.header__menu-btn');
  const header = document.querySelector('.header');
  const body = document.body;
  const html = document.documentElement;

  navigationBtn.addEventListener('click', () => {
    navigation.classList.toggle('active');
    navigationBtn.classList.toggle('active');
    header.classList.toggle('menu-opened');
    body.classList.toggle('ov-hidden');
    html.classList.toggle('ov-hidden');
  });

  window.addEventListener('scroll', () => {
    const scroll = window.scrollY;
    if (scroll >= header.offsetHeight) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  });

  window.addEventListener('load', () => {
    document.body.style.paddingTop = `${header.offsetHeight}px`;
  });

  window.addEventListener('resize', () => {
    document.body.style.paddingTop = `${header.offsetHeight}px`;
  });
}
