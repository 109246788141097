import throwError from '../utils/error';

export default async function updateStripeAccount (id, action = 'reset', content = null) {
    if (action !== 'reset' && content) {
        try {
            const response = await fetch(`/wp-json/icabbi/v1/update_account`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        id,
                        'notes': content,
                        'driver_notes': content
                    })
                }
            );
    
            if (response.ok) {
                const data = await response.json();
    
                if (data && data.length) return data;
    
                return false;
            }
    
            return false;
        } catch (error) {
            console.error('Error while updating an account:', error);
            throwError('show', 'Something went wrong, please try again');
    
            return false;
        }
    } else {
        try {
            const response = await fetch(`/wp-json/icabbi/v1/update_account`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        id,
                        'notes': '',
                        'driver_notes': ''
                    })
                }
            );
    
            if (response.ok) {
                const data = await response.json();
    
                if (data && data.length) return data;
    
                return false;
            }
    
            return false;
        } catch (error) {
            console.error('Error while updating an account:', error);
            throwError('show', 'Something went wrong, please try again');
    
            return false;
        }
    }
}
