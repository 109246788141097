export default function checkReturnDate (pDate, rDate) {
    const pDateFormatted = new Date(`${pDate.d}T${pDate.h}:${pDate.m}:00`);
    const rDateFormatted = new Date(`${rDate.d}T${rDate.h}:${rDate.m}:00`);

    if (rDateFormatted > pDateFormatted) {
      return true;
    }

    return false;
}
